@font-face {
    font-family: "fonticons";
    src: 
    url("./fonticons.ttf?a04bd76b90d1c05409ac628cf41d10c5") format("truetype"),
    url("./fonticons.woff?a04bd76b90d1c05409ac628cf41d10c5") format("woff"),
    url("./fonticons.woff2?a04bd76b90d1c05409ac628cf41d10c5") format("woff2"),
    url("./fonticons.eot?a04bd76b90d1c05409ac628cf41d10c5#iefix") format("embedded-opentype"),
    url("./fonticons.svg?a04bd76b90d1c05409ac628cf41d10c5#fonticons") format("svg");
}

i[class^="fonticon-"]:before, 
i[class*=" fonticon-"]:before {
    font-family: fonticons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fonticon-bicycle:before {
    content: "\f101";
}
.fonticon-delivery:before {
    content: "\f102";
}
.fonticon-bookmark:before {
    content: "\f103";
}
.fonticon-like:before {
    content: "\f104";
}
.fonticon-microphone:before {
    content: "\f105";
}
.fonticon-location:before {
    content: "\f106";
}
.fonticon-gallery:before {
    content: "\f107";
}
.fonticon-share:before {
    content: "\f108";
}
.fonticon-star:before {
    content: "\f109";
}
.fonticon-trash:before {
    content: "\f10a";
}
.fonticon-user:before {
    content: "\f10b";
}
.fonticon-view:before {
    content: "\f10c";
}
.fonticon-user-2:before {
    content: "\f10d";
}
.fonticon-pin:before {
    content: "\f10e";
}
.fonticon-chat:before {
    content: "\f10f";
}
.fonticon-home:before {
    content: "\f110";
}
.fonticon-mail:before {
    content: "\f111";
}
.fonticon-settings:before {
    content: "\f112";
}
.fonticon-alignment-right:before {
    content: "\f113";
}
.fonticon-link:before {
    content: "\f114";
}
.fonticon-attach:before {
    content: "\f115";
}
.fonticon-smile:before {
    content: "\f116";
}
.fonticon-moon:before {
    content: "\f117";
    margin-top: 0.1em;
}
.fonticon-sun:before {
    content: "\f118";
    margin-top: 0.1em;
}
.fonticon-train:before {
    content: "\f119";
}
.fonticon-eclipse:before {
    content: "\f11a";
    margin-top: 0.1em;
}
.fonticon-drone:before {
    content: "\f11b";
}
.fonticon-truck:before {
    content: "\f11c";
}
.fonticon-ship:before {
    content: "\f11d";
}
.fonticon-offline:before {
    content: "\f11e";
}
